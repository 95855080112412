.flex_box[data-v-82eb13ba] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancel[data-v-82eb13ba] {
  color: #17a2b8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 40px;
}
[data-v-82eb13ba] .el-collapse-item__content {
  padding-bottom: 0;
}
[data-v-82eb13ba] .el-collapse-item__header {
  height: 30px;
  line-height: 30px;
}
.form_style .el-form-item[data-v-82eb13ba] {
  margin-bottom: 5px;
}